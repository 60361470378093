















import { Vue, Component, Prop, VModel, Emit } from 'vue-property-decorator';

@Component({})
export default class ConfirmationDialog extends Vue {
  @VModel()
  showDialog!: boolean;

  @Prop()
  msg!: string;

  @Prop()
  header!: string;

  @Prop({ default: 'secondary' })
  color!: string;

  @Prop({ default: false })
  onlyWarning!: boolean;

  @Prop({ default: '25%' })
  confirmationWidth!: string;

  @Emit('choice')
  dialogChoice(choice: boolean | string): boolean | string {
    this.showDialog = false;
    return choice;
  }
}

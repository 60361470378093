
























import { Vue, Component, InjectReactive, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ConversationService from '@/services/crm/conversation.service';
import { ConversationContactStatusEnum } from '@/enums/crm/conversation-contact-status.enum';
import ContactModel from '@/models/crm/contact.model';
import { ICreateConversation } from '@/interfaces/crm/create-conversation.interface';

@Component({})
export default class CrmChatCallerButton extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @InjectReactive('conversationUserPermission') readonly conversationUserPermission!: ConversationUserPermissionsModel;

  @Prop({ required: true })
  contactNumber!: string;

  @Prop()
  contact!: ContactModel;

  @Prop()
  width!: boolean | string;

  @Prop()
  height!: boolean | string;

  @Prop()
  color!: string;

  @Prop({ default: false })
  disabled!: boolean;

  loading = false;

  async onClick(): Promise<void> {
    this.loading = true;
    try {
      if (this.canOpenConversation()) {
        const checkResult = await this.conversationService.checkContactNumber(this.contactNumber);
        const validWaContact = checkResult.find((x) => x.status === ConversationContactStatusEnum.Valid);

        if (validWaContact) {
          const payload: ICreateConversation = {
            contact: this.contact,
            waContact: validWaContact,
          };
          this.$store.commit('createConversation', payload, { root: true });
          this.$emit('create-conversation', payload);
        } else {
          this.$notify.warn(
            this.$t('crm.chatCallerButton.messages.couldNotFoundAValidWaIdForNumber', {
              contactNumber: this.contactNumber,
            }),
            5000,
          );
        }
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.loading = false;
    }
  }

  get showButton(): boolean {
    return !!this.conversationUserPermission;
  }

  private canOpenConversation(): boolean {
    if (!this.conversationUserPermission) {
      this.$notify.error(this.$t('crm.chatCallerButton.messages.errorOnGetPermissions'), 5000);
      return false;
    }

    if (!this.conversationUserPermission.viewWhatsApp) {
      this.$notify.warn(this.$t('crm.chatCallerButton.messages.whatsappDisabled'), 5000);
      return false;
    }

    if (!this.conversationUserPermission.manageWhatsApp) {
      this.$notify.warn(this.$t('crm.chatCallerButton.messages.youDoNotHavePermissiontoManageWhatsApp'), 5000);
      return false;
    }

    return true;
  }
}

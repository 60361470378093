





import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import ProcessService from '@/services/crm/process.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ProcessKanbanSettingsModel from '@/models/crm/process-kanban-settings.model';
import { i18n } from '@/i18n-setup';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({})
export default class CrmKanbanViewer extends Vue {
  @inject(InjectionIdEnum.CrmProcessService)
  private processService!: ProcessService;

  @Prop({ required: true })
  readonly flowId!: number;

  @Prop({ required: true })
  readonly clientId!: string;

  @Prop({ required: true })
  readonly clientType!: ClientTypeEnum;

  @Prop()
  readonly defaultQueue!: number;

  @Prop()
  readonly processId!: number;

  private kanbanSettings: ProcessKanbanSettingsModel | null = null;

  async mounted(): Promise<void> {
    const loader = this.$loading.show({
      container: this.$refs.kanbanViewerContainer,
      canCancel: false,
    });
    try {
      const settings = await this.processService.getKanbanSettings();

      if (!settings || settings.flagHabilitaKanban === 1) {
        this.kanbanSettings = settings;
      } else {
        this.$notify.error(i18n.t('crm.processKanbanIsDisabledInIgs'));
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  get kanbanUrl(): string {
    if (!this.kanbanSettings) {
      return '';
    }

    return this.processService.getKanbanUrl(
      this.kanbanSettings.urlKanbanApp,
      this.kanbanSettings.urlKanbanAPI,
      this.flowId,
      this.clientId,
      this.clientType,
      this.defaultQueue,
      this.processId,
    );
  }
}

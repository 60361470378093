




































import { inject } from 'inversify-props';
import { Vue, Component, Watch, Emit, Prop } from 'vue-property-decorator';
import SessionService from '@/services/session.service';
import ClientService from '@/services/crm/client.service';
import ClientModel from '@/models/crm/client.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({})
export default class CrmClientSearchField extends Vue {
  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  private readonly debounce = 450;

  private debounceId!: number;

  selectedClient: ClientModel | null = null;

  search = '';

  loading = false;

  items: ClientModel[] = [];

  @Prop()
  client!: ClientModel | null;

  @Prop({ required: true })
  clientType!: ClientTypeEnum;

  @Prop({ default: false })
  noStyle!: boolean;

  @Prop({ default: false })
  disableAutoComplete!: boolean;

  @Emit('client-selected')
  onSelectClient(): ClientModel | null {
    return this.selectedClient;
  }

  @Watch('client', { immediate: true })
  clientChanged(value: ClientModel | null): void {
    if (value) {
      this.addClientToSearchItems(value);
      this.selectedClient = value;
    }
    if (this.selectedClient && this.sessionService.activeClient) {
      if (value && this.sessionService.activeClient.codCliente !== this.selectedClient.codCliente) {
        this.sessionService.activeClient = value;
        this.selectedClient = value;
      } else {
        this.selectedClient.nomeFantasia = (this.sessionService.activeClient.nomeFantasia || '').trim()
          || (this.sessionService.activeClient.nome || '').trim();
      }
    } else if (!this.selectedClient && this.sessionService.activeClient) {
      this.selectedClient = this.sessionService.activeClient;
    }
  }

  @Watch('clientType')
  clientTypeChanged(): void {
    this.items = [];
  }

  @Watch('search')
  watchSearch(value: string): void {
    clearTimeout(this.debounceId);
    if (this.disableAutoComplete) {
      return;
    }
    if (!value || !value.length) {
      return;
    }

    if (value.length < 2) {
      this.items = [];
      this.loading = false;
      return;
    }

    let searchTerm = value;
    if (/\d/.test(searchTerm) && (searchTerm[3] === '.' || searchTerm[2] === '.')) {
      searchTerm = searchTerm.replace(/[^0-9]/g, '');
    }

    this.loading = true;
    this.debounceId = setTimeout(async () => {
      try {
        const result = await this.clientService.quickSearch(searchTerm, this.clientType);
        this.items = result.map((item) => {
          const newItem = item;
          newItem.nomeFantasia = newItem.nomeFantasia || newItem.nome;
          return newItem;
        });
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.loading = false;
      }
    }, this.debounce);
  }

  onBlurField(): void {
    if (!this.client) {
      return;
    }

    this.addClientToSearchItems(this.client);
    if (!this.selectedClient) {
      this.selectedClient = this.client;
    }
  }

  addClientToSearchItems(client: ClientModel): void {
    if (!this.items.find((item) => item?.codCliente && item?.codCliente === client?.codCliente)) {
      this.items = [client];
    }
  }

  get searchFieldLabel(): string {
    if (this.noStyle) {
      return this.$t(`crm.clientSearchField.${this.isProspectType ? 'prospect' : 'client'}`).toString();
    }
    return this.$t(
      `crm.clientSearchField.${this.isProspectType ? 'searchForAProspect' : 'searchForAClient'}`,
    ).toString();
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }
}
